@import "variables.scss";
@import "flex.scss";
@import "global.scss";
@import "styles.scss";
@import "themes.scss";

//_______________________________
//__  ___/_  ____/_  ___/_  ___/    # id
//_____ \_  /    _____ \_____ \     . class
//____/ // /___  ____/ /____/ / 
//_____/ \____/  /____/ /____/
/*           _      ____  ___ 
  __ _ ___ _(____  / __/ / _ \ /////////////////////////////
 /  ' / _ `/ / _ \/__ \_/ // //////// build 7.0 ///////////
/_/_/_\_,_/_/_//_/____(_\__*/
/* ___ ___ ___ 
  / __/ __/ __|    
 | (__\__ \__ \    
  \___|___/__*/ //only seen on css

//NAVIGATION MOBILE
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.nav-wrapper {
  display: flex;
  position: fixed;
  margin: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.nav-container {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  text-transform: lowercase;
  background: $bgblack;
  font-family: $montserratbold;
  padding: 0;
  width: 100%;
  height: 0;
}

.pizza {
  max-width: calc(1393px + 12rem);
  max-width: 1393px;
  display: flex;
  justify-content: center;
}

.fx-nav {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  text-transform: lowercase;
  background: $bgblack;
  font-family: $montserratbold;
  padding: 0;
  width: 100%;
  height: 0;
}

.nav-hidden {
  color:#FFF;
  position: absolute;
  top: $nav_height_negative;
  width: $nav_width;
  height: $nav_height;
  transition: all .5s ease;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  /* DESKTOP */
  @media only screen and (min-width: 1160px) {
    top: $nav_media_height_negative;
    width: $nav_media_width;
    height: $nav_media_height;
  }

  /* PORTRAIT */
  @media only screen and (max-height: $nav_height) {
    flex-wrap: wrap;
    top: $nav_media_height_negative;
    width: $nav_media_width;
    height: 0;
  }
}

.nav-hidden-item {
  transition: all .25s ease;
}

.html-icon-1 {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
}

//MENU DROP DOWN
li {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  width: $nav_width;

  /* DESKTOP */
  @media only screen and (min-width: 1160px) {
    width: $nav_media_width;
  }

  /* PORTRAIT */
  @media only screen and (max-height: $nav_height) {
    width: $nav_media_width;
  }
}

.nav-graphic-up { //top arrow
  background: url(../images/up_arrow.svg) center / 100% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-tape { //demo
  background: url(../images/demo-tape.svg) center / 145% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-illustration { //illustration
  background: url(../images/alivia.svg) center / 85% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-animation { //animation
  background: url(../images/anio-disk.svg) center / 100% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-pizza { //projects
  background: url(../images/pizza2.svg) center / 100% no-repeat;
  transition: all .25s ease;
    svg {
      color:rgb(204, 255, 0);
    }
}

.nav-graphic-contact { //contact
  background: url(../images/skate2.svg) center / 100% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-photo { //photo
  background: url(../images/camera.svg) center / 95% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-in { //linkedin 
  background: url(../images/in_logo.svg) center / 70% no-repeat;
  transition: all .25s ease;
}

.nav-graphic-be { //behence
  background: url(../images/be_logo.svg) center / 70% no-repeat;
  transition: all .25s ease;
}

.nav-item {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-decoration: none;
  //background-color: $grey900;
  height: $nav_width;
  padding-bottom: 7px;

  &:hover {
    transition: all 0.25s;
    color: #fff;
  }

  /* DESKTOP */
  @media only screen and (min-width: 1160px) {
    height: $nav_media_width;
  }

  /* PORTRAIT */
  @media only screen and (max-height: $nav_height) {
    height: $nav_media_width;
  }
}

.nav-item_text {
  padding: 0;
  border-radius: 0;
  font-size: 1rem;
  color: $pink;
  background: $darkblue;
  box-shadow: 0.5em 0 $darkblue, -0.5em 0 $darkblue;
}

//hovers
#home { //nav-graphic-up
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/up_arrow_blu.svg) 50% / 100% no-repeat;
      transition: all .25s ease;
    }
  }
}

#remix { //nav-graphic-tape
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/demo-tape_blu.svg) 50% / 195% no-repeat;
      transition: all .25s ease;
    }
  }
}

#illustration { //nav-graphic-illustration
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/alivia_blu.svg) 50% / 160% no-repeat;
      transition: all .25s ease;
    }
  }
}

#animation { //nav-graphic-animation
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/anio-disk_blu.svg) 50% / 130% no-repeat;
      transition: all .25s ease;
    }
  }
}

#pizza { //nav-graphic-projects
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/pizza_bw2.svg) 50% / 150% no-repeat;
      transition: all .25s ease;
    }
  }
}

#contact { //nav-graphic-contact
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/skate_blu.svg) 50% / 150% no-repeat;
      transition: all .25s ease;
    }
  }
}

#photo { //nav-graphic-photo
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/camera_blu3.svg) 50% / 150% no-repeat;
      transition: all .25s ease;
    }
  }
}

#linked { //nav-graphic-in
  .nav-item {
    background-color: $darkblue;
    &:hover {
      background: $white url(../images/in_logo_blu.svg) 50% / 70% no-repeat;
      //transition: all .25s ease;
    }
  }
}

//HAMBURGER ////////////////////////////////////////////////////////
#tio { //hide actual toggle switch
  position: fixed;
  appearance:none;
  cursor:pointer;
  left:-100%;
  top:-100%;
}

//black hamburger pizza
#nav-toggle + label {
  /* background: url("../images/hamburger.svg") transparent no-repeat; */
  background: url("../images/va_logo_pink.svg") transparent no-repeat;
  background-position: center center;
  background-size: 100%;
  background-color: $white;
  position:fixed;
  cursor:pointer;
  transition: all .5s ease;
  width: $nav_width;
  height: $nav_width;
  color: $white;

  /* DESKTOP */
  @media only screen and (min-width: 1160px) {
    width: $nav_media_width;
    height: $nav_media_width;
  }

  /* PORTRAIT */
  @media only screen and (max-height: $nav_height) {
    width: $nav_media_width;
    height: $nav_media_width;
  }
}

//pink hamburger
#nav-toggle:checked + label {
  background: url("../images/va_logo_yellow.svg") transparent no-repeat;
  background-position: center center;
  background-size: 100%;
  background-color: $darkblue;
  margin-top: $nav_height;
  //transition: all .5s ease;
  width: $nav_width;
  height: $nav_width;

  /* DESKTOP */
  @media only screen and (min-width: 1160px) {
    height: $nav_media_width;
    width: $nav_media_width;
    margin-top: 48rem;
  }

  /* PORTRAIT */
  @media only screen and (max-height: $nav_height) {
    margin-top: 24rem;
    margin-right: 0;
    z-index: 1;
    height: $nav_media_width;
    width: $nav_media_width;
  }
}

//hidden nav when checked
#nav-toggle:checked ~ .nav-hidden {
  display: flex;
  top: 0;
  transition: all .5s ease;

    /* PORTRAIT */
    @media only screen and (max-height: $nav_height) {
      height: 24rem;
      width: 12rem;
      top: auto;
    }
}
    
#nav-toggle + label:after {
  content:"+";
  color: $pink;
  position: relative;
  left: 8px;
  top: -5px;
}

#nav-toggle:checked + label:after {
  content:"-";
  color: $bgblack;
  position: relative;
  left: 8px;
  top: -5px;
}
/////////////////////////// END HAMBURGER ///////////////////////////////

////////////////////////////
// PARALAX SCROLLER //
///////////////////////////

.parallax {
  max-width: 1234px;
  height: 100%;
  transition: all .25s ease;

  /* DESKTOP */
  @media only screen and (max-width: 1200px) {
    height: 648px;
  }
}

//PARALLAX
// need to add mobile veriosn..... 
#artlayers {
  height: $artlayers; //important! keeps parallax from overflowing
  flex: 1 1 auto;
  min-height: $artlayers;
}

.artlayer {
  background-position: bottom center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: fixed;
  z-index: -1;
  min-height: $artlayers;
  max-width: 1234px;

  /* Portrait */
  @media only screen and (max-width: 600px) {
    height: 468px;
    max-width: 626px;
  }
}

.artlayer-bg {
  background: $white;
}

.artlayer-1 {
  background-image: url("../images/art_01.png");
  //background-image: url("#{$imgpath}/art_01.png");
}

.artlayer-2 {
  background-image: url("../images/art_02.png");
  //background-image: url("#{$imgpath}/art_02.png");
}

.artlayer-3 {
  background-image: url("../images/art_03.png");
  //background-image: url("#{$imgpath}/art_03.png");
}

.artlayer-4 {
  background-image: url("../images/art_04.png");
  //background-image: url("#{$imgpath}/art_04.png");
}

.artlayer-5 {
  background-image: url("../images/art_05.png");
  //background-image: url("#{$imgpath}/art_05.png");
}

.artlayer-6 {
  background-image: url("../images/art_06.png");
  //background-image: url("#{$imgpath}/art_06.png");
}

video::-webkit-media-controls-overlay-enclosure {
  display:none !important;
}

video::-webkit-media-controls-enclosure {
  display:none !important;
}

video::-webkit-media-controls {
  display:none !important;
}

////////////////////////////////////////////////////////////////////////
//VIDEO an
/* #demo_bookmark {} */

#section-header {
  display: flex;
  flex-direction: row;
  background: $white;

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: 11rem $mobile-padding 0;
  }
}

.demo-icon {
  background: url(../images/anio-disk_bw.svg) no-repeat;
  background-position: 100% 0%;
  background-size: 100%;
  height: 3.7rem;
  width: 9rem;
  margin: 0 .25rem;
}

.illustration-icon {
  background: url(../images/alivia_bw2.svg) no-repeat;
  background-position: 100% 0%;
  background-size: 100%;
  height: 3.7rem;
  width: 12rem;
  margin: 0 .25rem;
  background-position: 0 -85px;
}

.illustration {
  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: $mobile-padding;
  }
}

.remix-icon {
  background: url(../images/demo-tape_bw2.svg) no-repeat;
  background-position: 100% 0%;
  background-size: 100%;
  height: 3.7rem;
  width: 9rem;
  margin: 0 .25rem;
}

.photo-icon {
  background: url(../images/camera_bw4.svg) no-repeat;
  background-position: 100% 0%;
  background-size: 100%;
  height: 3.7rem;
  width: 9rem;
  margin: 0 .25rem;
}

.contact-icon {
  background: url(../images/aboutruck_bw2.svg) no-repeat;
  background-position: 100% 0%;
  background-size: 100%;
  height: 3.7rem;
  width: 9rem;
  margin: 0 .25rem;
}

.photo-full {
  margin-bottom: 7rem;
  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: $padding-xl;
  }
}

.splash--bkg {
  background: $white;

  .fx-container {
    background: $white;
  }

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: 0 2rem 2rem;
  }
}

.splash {
  padding: 2rem 0;
}

video {
  //position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-wrap {
  margin: 0 auto;
  width: 100%;

  iframe {
    border: none;
  }

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: $mobile-padding;
  }
}

.video {
  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: $mobile-padding;
  }
}

/* video#video-demoreel__video {
  margin: 0 auto;
  min-width: 100%;
  min-height: auto;
  width: 100%;
  background-size: cover;
  box-sizing: unset;

  *, *::before, *::after {
    width: 100%;
  }
} */

/* #video-demoreel__controls {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

#video-demoreel__controls button {
  display: inline-block;
  margin: 5px;
  padding: 10px 25px;
  background-color: $grey600;
  color: $pink;
  font-family: $montserratbold;
  font-size: 20px;
  outline: none;
  cursor: pointer;
  border: none;
  box-shadow: none;
  appearance: none;
  border-radius: 10px;
  text-transform: lowercase;
} */

/* .play-pause {
  background: url(../images/play_btn.svg) 100% / 50% no-repeat;
} */

//ILLUSTRATION
#illustration-container {
  margin: auto;
}   

$title-pad: 0.25em;
$title-lineheight: $title-pad + 1;

.img-text {
  line-height: $title-pad + 1em;
  text-transform: uppercase;
  margin-top: -36px;
  margin-bottom: 0;
  margin-left: -1px;
  font-size: 1.25em;
  line-height: 1.25em;
  display: flex;

  .highlight {
    display: inline;
    background: $white;
    padding: $title-pad;
  }

  .highlight--wrapping {
    position: relative;
    left: $title-pad;
    padding-left: 0;
    box-shadow: $title-pad 0 0 $white,  
                $title-pad*-1 0 0 $white;
  }
}

//ILLUSTRATIONS
//PHOTO
//LIGHTBOX MARKUP

.graphic {
  width: 100%;
  border-radius: .25rem;
  /* mobile  */
  @media only screen and (max-width: 1200px) {
    border-radius: 1rem;
  }
}

.graphic--remix {
  width: 100%;
  border-radius: .25rem;
  /* mobile  */
  @media only screen and (max-width: 1200px) {
    border-radius: 5px;
  }
}

.lightbox {
	// Default lightbox to hidden
	display: none;
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
  background: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(255, 255, 255));
}

.lightbox img {
  margin: auto;
  padding: 10%;
}

.lightbox:target {
	//Remove default browser outline
	outline: none;
	//Unhide lightbox 
  display: flex; 
  overflow: auto; //scroll enabled
}

#buy-btn {
  justify-content: flex-start;
    display: flex;
    margin-right: 1rem;
}

#social--yt-btn {
  justify-content: flex-start;
    display: flex;
    margin-right: 1rem;
}

.buy-btn {
  background-color: $white;
  background-image: url(../images/amz_btn.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  background-color: $white;
  height: 50px;
  width: 88px;
  transition: all .25s ease;
  border-radius: 10px;
  position: absolute;
  margin: 2rem;
  transform: rotate(-10deg);
}

.social--yt-btn {
  background-color: $white;
  background-image: url(../images/yt_logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  background-color: $white;
  height: 50px;
  width: 88px;
  transition: all .25s ease;
  border-radius: 10px;
  position: absolute;
  margin: 2rem;
  transform: rotate(-10deg);
}

//ANIMATIONS
.ani-01 {
  background-color: $white;
  background-image: url(../images/animate_this_pizza-01.svg);
  background-repeat: no-repeat;
  background-position: center
}

.color_item {
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
  height: 6px;
  font-size: 1rem;
  text-align: center;
}

//TRIAL PAGES WITHIN HTML
.body {
  margin: 0em;
}

.page {
 width: 100vw;
 height: auto;
 position: fixed;
 top: 0;
 left: -100vw;
 overflow-y: auto;
 z-index: 0;
 background-color: rgb(255, 0, 191);
}

.page:target {
 left: 0vw;
 z-index: 1;
}

//JS HTML trial 2 
//FOOTER CONTACT
.c-01 {
  background-color: #533B4D;
}

.c-02 {
  background-color: rgb(204, 255, 0);
}

.c-03 {
  background-color: #FFFF00;
}

.c-04 {
  background-color: #FFE200;
}

.c-05 {
  background-color: #fff;
}

.c-06 {
  background-color: #80F0FF;
}

.c-06 {
  background-color: #4CDCEF;
}

.c-07 {
  background-color: #2CC1E5;
}

.c-08 {
  background-color: #727272;
}

.c-09 {
  background-color: #FF95BE;
}

.c-10 {
  background-color: #C396FF;
}

.c-11 {
  background-color: #A361DB;
}

.c-12 {
  background-color: #88F0D4;
}

.c-13 {
  background-color: #6AD1C1;
}

.c-14 {
  background-color: #249DBA;
}

.c-15 {
  background-color: #35EB35;
}

.c-16 {
  background-color: #93FF27;
}

.c-17{
  background-color: #D8D8D8;
}

.c-18 {
  background-color: #FF4E4E;
}

.c-19 {
  background-color: rgb(255, 35, 101);
}

.c-20 {
  background-color: rgb(255, 255, 255);
}

//CONTACT
.green {
  background: $ngreen;

  .fx-container,
  .row {
    background: $ngreen;
  }

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: 0 2rem 2rem;
  }
}

.logo--svg {
  background: url("../images/voitekart_logo_back.svg") top left / 76% no-repeat;
  height: 10rem;
  margin-left: -1rem;
  max-width: 106rem;

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    margin-left: -3rem;
  }
}

.padding-top {
  padding-top: 7rem;

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding-top: 2.5rem;
  }
}


.contact {
  display: flex;
}

.img_selfie {
  background-image: url("../images/voitek_pic.svg");
  background-repeat: no-repeat;
  padding: 37px;
  margin: .75rem 1rem 0 0;
}

.text--bio {
  max-width: 80%;
  line-height: normal;
  text-align: left;
  letter-spacing: 2px;
  font-weight: 200;
  color: $bgblack;
  font-size: 17px;
  padding: $default-padding;

  /* DESKTOP  */
  @media only screen and (max-width: 1200px) {
    font-size: 21px;
  }
}

.text--C13 {
  max-width: 1234px !important;
  line-height: normal;
  text-align: left;
  letter-spacing: 2px;
  font-weight: 100;
  font-size: smaller;
  text-align: justify;
  color: $white20;
  padding: 1rem;
  margin: auto !important;
}

.social-link {
  background-repeat: no-repeat;
  width: 21em;
  height: 22px;
  background-position: 97% 0;
  line-height: normal;
  margin-left: -6em;
}

.social--buttons {
  display: none !important;
}

.social--buttons,
.social--buttons_light {
  justify-content: center;
  align-items: center;
}

.social_button {
  display: flex;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin: 1.25rem 3rem;

  /* DESKTOP  */
  @media only screen and (max-width: 1200px) {
    width: 2.75rem;
    height: 2.75rem;
    margin: 2rem 2.75rem;
  }

  &:hover {
    background: $pink 100%;
    //padding: 1rem;
    transition: all .25s ease;
    border-radius: .75rem;
    height: 50%;
  }
}

.li--btn {
  background-position: center;
  background-size: 100%;
}

.yt--btn {
  background-position: center;
  background-size: 100%;
}

.inst--btn {
  background-position: center;
  background-size: 100%;
}

//COPYRIGHT
.copyright {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.copyright p {
  font-size: small;
  line-height: 0.25em;
  letter-spacing: 7px;
  text-transform: uppercase;    
  font-family: $montserrat;
  color: $bgblack;

  /* DESKTOP  */
  @media only screen and (max-width: 1200px) {
    font-size: x-small;
  }
}

//text copyright
.text--1 {
  margin: 1rem;
  p {
    line-height: normal;
  }
}

.email {
  margin: auto;
  line-height: initial;
  color: $grey900;
  letter-spacing: 7px;

  a {
    font-size: large;

    /* DESKTOP  */
    @media only screen and (max-width: 1200px) {
      font-size: xx-large;
    }
  }
}

//FOOTER
.footer_container {
  display: flex;
  position: relative;
  justify-content: center;
}

  footer {
    clear: both;
    position: relative;
    display: flex;
    z-index: 0;
  }

  footer p {
    display: block;
    width: 300px;
    color: $pink; 
    font-family: $montserrat;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 77px;
    line-height: normal;
  }

  .sll {
    align-items: center;
  }

[class^="icon-"]:before,
[class*="icon-"]:before {
  display: inline;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

/* For mobile phones: */
[class*="column-"] {
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: $padding-s 0 $padding-s;
  background: $white;

  /* MOBILE  */
  @media only screen and (max-width: 1200px) {
    padding: $mobile-padding;
  }
}


@media only screen and (min-width: 768px) {
  /* For desktop: */
  .column-1 {width: 8.33%;}
  .column-2 {width: 16.66%;}
  .column-3 {width: 25%;}
  .column-4 {width: 33.33%;}
  .column-5 {width: 41.66%;}
  .column-6 {width: 50%;}
  .column-7 {width: 58.33%;}
  .column-8 {width: 66.66%;}
  .column-9 {width: 75%;}
  .column-10 {width: 83.33%;}
  .column-11 {width: 91.66%;}
  .column-12 {width: 100%;}
  .column-25 {
    flex: 50%;
    max-width: 50%;

    /* large desktop */
    @media only screen and (min-width: 1200px) {
      flex: 25%;
      max-width: 25%;
    }
  }


  .column-33 {
    flex: 50%;
    max-width: 50%;
    justify-content: center;
    
    /* large desktop */
    @media only screen and (min-width: 1200px) {
      flex: 33.33%;
      max-width: 33.33%;
    }
  }

  .column-50 {
    flex: 100%;
    max-width: 100%;
    justify-content: center;
    
    /* large desktop */
    @media only screen and (min-width: 1200px) {
      flex: 33.33%;
      max-width: 33.33%;
    }
  }

  .column-100 {
    flex: 100%;
    max-width: 100%;
    justify-content: center;
    
    /* large desktop */
    @media only screen and (min-width: 1200px) {
      flex: 50%;
      max-width: 50%;
    }
  }

  .column-75--33 {
    flex: 100%;
    max-width: 100%;
    justify-content: center;
    
    /* large desktop */
    @media only screen and (min-width: 1200px) {
      flex: 33.33%;
      max-width: 33.33%;
    }
  }
  
}
